<template>
  <footer class="page-footer">
    <div class="footer-copyright">
      <div class="font-color container">
        © 2024 Copyright
        <a class="text-lighten-4 right" href="https://github.com/peter910820">
          Creater: SeaotterMS</a
        >
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>

<style scoped>
footer {
  background-color: #f2ebea;
}
.font-color {
  color: #444444;
}
</style>
