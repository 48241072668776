import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mainStyle" }
const _hoisted_2 = { class: "sticky-navbar" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col l9 m12 s12" }
const _hoisted_5 = { class: "col l3 m12 s12 sticky-profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MyProfile = _resolveComponent("MyProfile")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NavBar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_view)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MyProfile)
      ])
    ]),
    _createVNode(_component_MainFooter)
  ]))
}